import React from "react";
import CaseRoll from "./CaseRoll";
import { Section, Title, Text } from "./Core";
import { Container, Row, Col } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import PageWrapper from "./PageWrapper";

const Cases = (props) => {
  const intl = useIntl();

  return (
    <PageWrapper footerDark>
      <Section className="pb-0">
        <div className="pt-5 pt-lg-0"></div>
        <Container>
          <Row className="justify-content-md-center text-md-center">
            <Col lg="8">
              <Title>
                <FormattedMessage
                  id="cases.heading"
                  defaultMessage="Business cases"
                />
              </Title>
              <Text>
                <FormattedMessage
                  id="cases.subheading"
                  defaultMessage="At Tournify, we aim to support as many tournament or league organisers as possible. Therefore, we're proud to be able to collaborate with great partners who share our passion for sports and eSports."
                />
              </Text>
            </Col>
          </Row>
        </Container>
      </Section>
      <div className="pt-3"></div>
      <CaseRoll
        cta={intl.formatMessage({
          id: "cases.cta",
          defaultMessage: "Learn more",
        })}
        cases={props.data.allMarkdownRemark}
      />
    </PageWrapper>
  );
};

export default Cases;
